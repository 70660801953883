// ONLY MODIFY THIS FILE BY RUNNING THE FOLLOWING COMMAND:
// php artisan react:enum

export const CurrencyType = {
    USD: {
        Name: 'U S D',
        Value: 'USD',
        Symbol: '$',
    },
    CAD: {
        Name: 'C A D',
        Value: 'CAD',
        Symbol: 'CA$',
    },
    EUR: {
        Name: 'E U R',
        Value: 'EUR',
        Symbol: '€',
    },
    GBP: {
        Name: 'G B P',
        Value: 'GBP',
        Symbol: '£',
    },
    AUD: {
        Name: 'A U D',
        Value: 'AUD',
        Symbol: 'AU$',
    },
    JPY: {
        Name: 'J P Y',
        Value: 'JPY',
        Symbol: '¥',
    },
}